<template>
  <div
    style="height: inherit"
    :class="{ 'is-loading': pLoading }"
  >
    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div
      v-if="project"
      class="ecommerce-searchbar mt-1"
    >
      <b-card>
        <h3
          v-if="!titleEditable"
          class="mb-0"
        >
          <b-link :to="{ name: 'account:projects-index' }">
            <b-avatar
              rounded
              size="32"
              variant="light-primary"
              class="mr-75"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
              />
            </b-avatar>
          </b-link>
          <span v-text="project.title" />
          <b-button
            variant="primary"
            class="ml-2 p-50"
            @click="titleEditable = !titleEditable"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
            />
          </b-button>
        </h3>
        <b-row v-else>
          <b-col cols="10">
            <b-form-input
              id="project_title"
              v-model="project.title"
            />
          </b-col>
          <b-col cols="2">
            <b-button
              variant="primary"
              class="p-50"
              block
              @click="saveProjectTitle"
            >
              <feather-icon
                icon="SaveIcon"
                size="20"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <b-alert
      :show="!userData || !userData.telegram"
      variant="warning"
      class="p-1"
      v-html="$t('info.bot.get')"
    />

    <!-- Prodcuts -->
    <section :class="adaptiveView">
      <b-card
        v-for="task in tasks"
        :key="task.id"
        class="ecommerce-card"
        no-body
      >
        <!-- Product Details -->
        <b-card-body>
          <b-row>
            <b-col cols="4">
              <b-media>
                <template #aside>
                  <b-img
                    :src="$options.filters.mediaUrl(task.product, 'avatar', '150x150')"
                    rounded
                    width="50px"
                  />
                </template>
              </b-media>
            </b-col>
            <b-col
              cols="8"
              class="text-right"
            >
              <b-badge
                variant="light-secondary"
                style="padding: 10px"
              >
                <feather-icon
                  size="10"
                  :icon="getIconByStatus(task.status)"
                  class="mr-50"
                />
                {{ $t(`general.taskStatus.${task.status}`) }}
              </b-badge>
            </b-col>
            <b-col cols="12">
              <div class="line" />
            </b-col>
            <b-col cols="12">
              <div
                class="media-heading"
                style="width: auto"
              >
                <h5 class="cart-item-title">
                  {{ task.product.title }}
                </h5>
                <a
                  :href="task.product.link"
                  target="_blank"
                  class="cart-item-title"
                >
                  {{ task.product.link }}
                </a><br>
                <b-card-text
                  v-if="task.product.categories"
                  class="item-company"
                >
                  <b-badge
                    v-for="(category, cin) in task.product.categories"
                    :key="cin + '_' + 'cat'"
                    pill
                    class="mr-75"
                    variant="light-primary"
                  >
                    {{ category.title }}
                  </b-badge>
                </b-card-text>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="line" />
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="4">
              #{{ task.id }}
            </b-col>
            <b-col
              cols="8"
              class="right-col text-right"
            >
              <div class="design-planning">
                <p class="card-text mb-25">
                  {{ $t('project.task.label.subscribers') }}
                </p>
                <h6 class="mb-0">
                  {{ $options.filters.numberFormat(getChannelHistory(task.product)) }}
                </h6>
              </div>
            </b-col>
          </b-row>
        </b-card-body>

        <!-- Product Info -->
        <b-card-body class="card-app-design">
          <b-row>
            <b-col cols="4">
              <div class="design-planning">
                <p class="card-text mb-25">
                  {{ $t('project.task.label.price') }}
                </p>
                <h6 class="mb-0">
                  {{ $options.filters.moneyFormat(task.price) }}
                </h6>
              </div>
            </b-col>
            <b-col
              cols="8"
              class="right-col"
            >
              <div class="design-planning">
                <p class="card-text mb-25">
                  {{ $t('project.task.label.date') }}
                </p>
                <h6 class="mb-0">
                  <feather-icon
                    icon="CalendarIcon"
                    size="16"
                    class="text-primary mr-50"
                  />
                  {{ task.started_at }} - {{ task.ended_at }}
                </h6>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="line" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <div class="design-planning">
                <p class="card-text mb-25">
                  {{ $t('project.task.label.format') }}
                </p>
                <h6 class="mb-0">
                  {{ $t(`catalog.price.types.${task.type}`) }}
                </h6>
              </div>
            </b-col>
            <b-col
              cols="8"
              class="right-col"
            >
              <div class="design-planning">
                <p class="card-text mb-25">
                  {{ $t('project.task.label.time') }}
                </p>
                <h6 class="mb-0">
                  <feather-icon
                    icon="ClockIcon"
                    size="16"
                    class="text-primary mr-50"
                  />
                  {{ task.hours_started_at }} - {{ task.hours_ended_at }}
                </h6>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="line" />
            </b-col>
          </b-row>
          <b-row v-if="needShowTiming(task)">
            <b-col
              cols="12"
              class="right-col"
            >
              <div
                v-b-tooltip.hover.v-warning
                class="design-planning"
                :title="$t(`project.task.timing.description.${task.status}`)"
              >
                <p class="card-text mb-25">
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="16"
                    class="text-warning mr-50"
                  />
                  {{ $t(`project.task.timing.title.${task.status}`) }}
                </p>
                <div>
                  <Countdown :deadline="task.history[0].limited_at" />
                </div>
              </div>
            </b-col>
          </b-row>

          <a
            v-if="task.link"
            :href="task.link"
            target="_blank"
            class="pt-50"
          >
            <feather-icon
              size="14"
              icon="LinkIcon"
              class="mr-50"
            />
            {{ task.link }}
          </a>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="buttons">
            <b-button
              v-if="task.status !== 'draft'"
              variant="light"
              @click="openedTaskForm = true; openedTask = task"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-50"
              />
              {{ $t('project.task.btn.details') }}
            </b-button>
            <b-button
              v-if="task.status === 'pending'"
              variant="danger"
              @click="setStatus(task.id, 'cancel', '0', 'Відміна завдання замовником')"
            >
              <feather-icon
                icon="XCircleIcon"
                class="mr-50"
              />
              {{ $t('project.task.btn.cancel') }}
            </b-button>
            <b-button
              v-if="task.status === 'review'"
              variant="success"
              @click="setStatus(task.id, 'completed', '0', 'Виконання підтверджено'); openedTask = task"
            >
              <feather-icon
                icon="ThumbsUpIcon"
                class="mr-50"
              />
              {{ $t('project.task.btn.completed') }}
            </b-button>
            <b-button
              v-if="task.status === 'review'"
              variant="warning"
              @click="setStatus(task.id, 'revision', '0', 'Відправлено на доопрацювання')"
            >
              <feather-icon
                icon="ThumbsDownIcon"
                class="mr-50"
              />
              {{ $t('project.task.btn.revision') }}
            </b-button>
          </div>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section v-if="project">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="meta.total"
            :per-page="meta.per_page"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <filter-sidebar
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        :f-loading="fLoading"
      />
    </portal>

    <task-form
      :opened-task-form.sync="openedTaskForm"
      :task="openedTask | full_freeze"
      :set-status="setStatus"
      @refetch-data="fetchData"
    />
    <task-review
      :opened-task-review.sync="openedTaskReview"
      :task="openedTask | full_freeze"
      :write-review="writeReview"
      @refetch-data="fetchData"
    />
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import router from '@/router'
import _ from 'lodash'
import Countdown from 'vuejs-countdown'
import TaskForm from './TaskForm.vue'
import TaskReview from './TaskReview.vue'
import FilterSidebar from './FilterSidebar.vue'

export default {
  components: {
    FilterSidebar,
    TaskForm,
    Countdown,
    TaskReview,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      mqShallShowLeftSidebar: false,
      titleEditable: false,
      openedTaskReview: false,
      openedTaskForm: false,
      openedTask: {},
      fLoading: true,
      pLoading: true,
      tasks: [],
      project: null,
      itemView: 'list-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      sortBy: { text: this.$i18n.t('catalog.list.sorting.id-desc'), value: 'id-desc' },
      sortByOptions: [
        { text: this.$i18n.t('catalog.list.sorting.id-desc'), value: 'id-desc' },
        { text: this.$i18n.t('catalog.list.sorting.price-asc'), value: 'prices_sum_price-asc' },
        { text: this.$i18n.t('catalog.list.sorting.price-desc'), value: 'prices_sum_price-desc' },
      ],
      filterOptions: [
        {
          label: this.$t('general.taskStatus.draft'), value: 'draft', color: 'secondary', icon: 'CircleIcon',
        },
        {
          label: this.$t('general.taskStatus.pending'), value: 'pending', color: 'secondary', icon: 'ClockIcon',
        },
        {
          label: this.$t('general.taskStatus.work'), value: 'work', color: 'info', icon: 'PlayCircleIcon',
        },
        // {
        //   label: this.$t('general.taskStatus.appeal'), value: 'appeal', color: 'danger', icon: '',
        // },
        {
          label: this.$t('general.taskStatus.completed'), value: 'completed', color: 'success', icon: 'CheckCircleIcon',
        },
        {
          label: this.$t('general.taskStatus.review'), value: 'review', color: 'warning', icon: 'ThumbsUpIcon',
        },
        {
          label: this.$t('general.taskStatus.revision'), value: 'revision', color: 'warning', icon: 'ThumbsDownIcon',
        },
        {
          label: this.$t('general.taskStatus.cancel'), value: 'cancel', color: 'danger', icon: 'XCircleIcon',
        },
        {
          label: this.$t('general.taskStatus.return'), value: 'return', color: 'warning', icon: '',
        },
      ],
      currentPage: 1,
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 12,
        to: 1,
        total: 1,
      },
      adaptiveView: window.outerWidth > 1200 ? 'list-view' : 'grid-view',
    }
  },
  watch: {
    '$route.query': {
      handler() {
        this.debFetchData(this)
      },
      deep: true,
      immediate: true,
    },
    currentPage: {
      handler(page) {
        const params = {
          ...this.$route.query,
          ...{ page },
        }
        this.$router.push({ name: 'account:projects-details', params: { id: this.$router.currentRoute.params.id }, query: params })
      },
      deep: true,
      immediate: true,
    },
    sortBy: {
      handler(sb) {
        const params = {
          ...this.$route.query,
          ...{ sorting: sb.value },
        }
        this.$router.push({ name: 'account:projects-details', params: { id: this.$router.currentRoute.params.id }, query: params })
      },
      deep: true,
      immediate: true,
    },
  },
  beforeCreate() {
    this.$http.get(`/api/user/projects/${router.currentRoute.params.id}`)
      .then(response => {
        this.project = response.data.data
      })
      .catch(error => {
        this.project = null
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: error.message ?? this.$t('notifications.not_found'),
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        router.replace({ name: 'account:projects-index' })
      })
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.adaptiveView = window.outerWidth > 1200 ? 'list-view' : 'grid-view'
    },
    writeReview(id, rating, description) {
      this.$http.post('/api/user/reviews', {
        rating,
        description,
        reviewable_id: id,
        reviewable_type: 'Product',
      })
        .then(() => {
          this.fetchData()
          this.openedTaskReview = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    fetchData() {
      this.pLoading = true
      this.$http.get('/api/user/tasks/', {
        params: {
          sorting: this.sortBy.value,
          status: this.$route.query.status ?? null,
          per_page: this.meta.per_page,
          page: this.currentPage,
          project_id: this.$router.currentRoute.params.id,
        },
      })
        .then(response => {
          this.tasks = response.data.data
          this.meta = response.data.meta
          this.currentPage = response.data.meta.current_page
          this.pLoading = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    debFetchData: _.debounce(vm => {
      vm.fetchData()
    }, 350),
    setStatus(id, status, link = '0', comment = '') {
      this.confirm(result => {
        if (['cancel', 'revision'].includes(status) && result.value && result.value.length) {
          comment += ` | ${result.value}`
        }
        this.$http.put(`/api/user/tasks/${id}`, { status, link, comment })
          .then(() => {
            this.fetchData()
            if (status === 'completed') {
              this.openedTaskReview = true
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        input: ['cancel', 'revision'].includes(status) ? 'textarea' : null,
        inputPlaceholder: this.$t('notifications.confirm.comment'),
        title: this.$t(`notifications.confirm.${status}.task`),
        text: null,
      })
    },
    saveProjectTitle() {
      this.$http.put(`/api/user/projects/${this.project.id}/title`, { title: this.project.title })
        .then(() => {
          this.titleEditable = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success!',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    getDataByStatus(item, status) {
      let total = 0
      item.tasks.forEach(i => { total += (i.status === status ? 1 : 0) })
      return total
    },
    getIconByStatus(status) {
      return _.filter(this.filterOptions, o => o.value === status)[0].icon
    },
    getColorByStatus(status) {
      const fS = _.filter(this.statusTaskOptions, o => o.value === status)
      return fS && fS[0] ? fS[0].color : 'secondary'
    },
    getChannelHistory(item) {
      return item.history && item.history.length ? item.history[0].subscribers : 0
    },
    needShowTiming(item) {
      return ['review'].includes(item.status)
        && item.history
        && item.history.length
        && item.history[0].status === item.status
        && item.history[0].limited_at !== item.history[0].created_at
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.ecommerce-application {

  .grid-view {
    .ecommerce-card {
      .card-body {
        width: 100%;
      }

      .item-options {
        padding:1rem;

        // .buttons {
        //   width: 100%;
        //   display: flex;
        // }
      }
    }
  }

  .ecommerce-card {
    grid-template-columns: 3fr 4fr 2fr !important;
    overflow: visible !important;
    align-items: stretch;

    .line {
      padding: 0 0 10px;
      margin: 0 0 10px;
      border-bottom: 1px solid #ebe9f1;
    }

    .right-col {
      border-left: 1px solid #ebe9f1;
    }

    .card-app-design {
      background-color: rgba(115, 103, 240, 0.05);
    }

    .item-options {
      padding:1rem;

      .buttons {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 5px;
      }
    }
  }
}

</style>

<style lang="scss">
.ecommerce-card .vuejs-countdown {
  margin:0 0;
  border: 1px solid #ff9f43;
  background: #fff4e9;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ecommerce-card .vuejs-countdown li {
  margin: 0 4px;
}
.ecommerce-card .vuejs-countdown li:after {
  font-size: 20px;
  right: -6px;
}
.ecommerce-card .vuejs-countdown .digit {
  font-size: 22px;
}
.ecommerce-card .vuejs-countdown .text {
  display: none;
}
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>
